<template>
    <div>
        <b-row>
            <b-col sm="6" v-for="(item, i) in defaultSubscriptionPermissions" :key="i">
                <b-form-checkbox v-model="defaultSubscriptionPermissions[i].value" @input="PermissionEmitList">
                    <b>{{ item.name }}</b>
                </b-form-checkbox>
                <span class="small">
                    <i>{{ item.description }}</i>
                </span>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>

<script>
import { DefaultGateUserPermissions } from '@/variables/permissionVariables.js'
export default {
    props: {
        permissions: []
    },
    data() {
        return {
            defaultSubscriptionPermissions: DefaultGateUserPermissions
        }
    },
    methods: {
        PermissionEmitList() {
            this.$emit('permissionCallback', this.defaultSubscriptionPermissions.filter(x => x.value).map(x => x.permission));
        }
    },
    watch: {
        permissions: {
            handler() {
                if (this.permissions !== undefined) {
                    this.defaultSubscriptionPermissions.map((x) => {
                        if (this.permissions.length > 0) {
                            this.permissions.map((y) => {
                                if (y == x.permission) x.value = true;
                            });
                        }
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {

    }
}
</script>